import * as React from 'react'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import {Container, Row, Col} from 'react-bootstrap'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Not found" />
    <Container>
      <div style={{ minHeight: '80vh', display: "flex", flexDirection: 'row', paddingTop: '4em' }}>
        <div className="my-auto">
          <Row>
            <Col md={12} className='pb-4'>
              <h1 className='hero-text'>
                Oh no!
              </h1>
              <p className='pt-2'>
                We could not find what you were looking for!
              </p>
            </Col>
            {/* <Col md={5}>
							<StaticImage src="../images/screenshot.png" alt="Excerpt Screenshot" placeholder='blurred' />
						</Col> */}
          </Row>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
